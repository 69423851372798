import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AuthGuard } from "./public/shared/guards/auth.guard";
import { AuthInterceptorService } from "./public/shared/services/auth-interceptor.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StoreService } from "./public/shared/services/store.service";
import { NgxPermissionsModule, NgxPermissionsService } from "ngx-permissions";
import { MatNativeDateModule } from "@angular/material/core";

import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";
import { NgxMaskDirective, IConfig } from "ngx-mask";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

registerLocaleData(localeEs);
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    NgxPermissionsModule.forRoot(),
    MatNativeDateModule,
    NgxMaskDirective,
    NgbModule,
  ],
  exports: [TranslateModule, NgxPermissionsModule],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    StoreService,
    AuthInterceptorService,
    { provide: MAT_DATE_LOCALE, useValue: "es-SV" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
