import {
  Component,
  Renderer2,
  TemplateRef,
  OnInit,
  RendererFactory2,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxPermissionsConfigurationService } from "ngx-permissions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "MAERSK";
  constructor(
    public translate: TranslateService,
    private rendererFactory: RendererFactory2
  ) {
    translate.addLangs(["en", "es"]);
    translate.setDefaultLang("es");
    // translate.setDefaultLang('en');
    const lang = localStorage.getItem("lang");
    if (lang) {
      // translate.use(lang.match(/en|es/) ? lang : 'es');
      translate.use("es");
    } else {
      // const browserLang = translate.getBrowserLang();
      // translate.use(browserLang.match(/en|es/) ? browserLang : 'es');
      translate.use("es");
    }
  }

  ngOnInit(): void {
    // this.renderer2 = this.rendererFactory.createRenderer(null, null);
    // this.ngxPermissionsConfigurationService.addPermissionStrategy('disable', this.disabledFunc);
    // this.ngxPermissionsConfigurationService.addPermissionStrategy('enable', this.enableFunc);
    // this.ngxPermissionsConfigurationService.setDefaultOnAuthorizedStrategy('enable');
    // this.ngxPermissionsConfigurationService.setDefaultOnUnauthorizedStrategy('disable');
  }
}
