import BaseModel from "./base-model.model";
import { CoRoles } from "./co-roles.model";

export class CoUsers extends BaseModel {
  company_id: number;
  co_user_id: number;
  co_user_names: string;
  co_user_lastnames: string;
  co_user_email: string;
  co_user_phonenumber: string;
  co_user_position: string;
  co_user_username: string;
  co_user_password: string;
  co_user_2FA: string;
  co_user_ip_restricted: string;
  co_user_roles: CoRoles[];
  co_user_sucursales: any[];
  super() {
    this.company_id = undefined;
    this.co_user_id = undefined;
    this.co_user_names = "";
    this.co_user_lastnames = "";
    this.co_user_email = "";
    this.co_user_phonenumber = "";
    this.co_user_position = "";
    this.co_user_username = "";
    this.co_user_password = "";
    this.co_user_2FA = "";
    this.co_user_ip_restricted = "";
    this.co_user_roles = [];
    this.co_user_sucursales = [];
  }
}
