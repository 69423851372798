// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: "http://localhost:4200"
};
export const URL1: string = "https://dev-portal-api.ditopay.io";
export const PUBLIC_API: string = "https://dev-portal-api.ditopay.io/api/1.0";
export const APIS = {
  API_CORE_URL: URL1 + "/api/1.0/core",
  API_USERS_URL: URL1 + "/api/2.0/users",
  API_ROLES_URL: URL1 + "/api/1.0/company-roles",
  API_COMPANY_URL: URL1 + "/api/1.0/company",
  API_COMPANY_INFO_CUENTA_PERSONAL_URL:
    URL1 + "/api/1.0/company/info-cuenta-personal",
  API_COMPANY_INFO_CUENTA_JURIDICA_URL:
    URL1 + "/api/1.0/company/info-cuenta-juridica",
  API_COMPANY_CUENTA_BANCARIA_URL: URL1 + "/api/1.0/company/cuenta-bancaria",
  API_COMPANY_DOCS_URL: URL1 + "/api/1.0/company/docs",
  API_COMPANY_REP_LEGAL_URL: URL1 + "/api/1.0/company/info-rep-legal",
  API_COMPANY_USERS_URL: URL1 + "/api/1.0/company-users",
  API_COMPANY_BRANCHES_URL: URL1 + "/api/1.0/company-branches",
  API_COMPANY_BRANCHES_USERS_URL: URL1 + "/api/1.0/branches-users",
  API_COMPANY_BRANCHES_ROLES_URL: URL1 + "/api/1.0/branches-roles",
  API_COMPANY_WALLETS_URL: URL1 + "/api/1.0/company-wallets",
  API_COMPANY_BRANCHES_WALLETS_URL: URL1 + "/api/1.0/branches-wallets",
  API_COMPANY_BRANCHES_APPS_URL: URL1 + "/api/1.0/branches-apps",
  API_COMPANY_REPORTES: URL1 + "/api/1.0/reportes",
  API_COMPANY_PAYMENT_REQUESTS: URL1 + "/api/1.0/payment-requests",
  API_COMPANY_STAKEHOLDERS: URL1 + "/api/1.0/stakeholders",
  API_COMPANY_PEPS: URL1 + "/api/1.0/pep",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
