import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, from } from "rxjs";
import { APIS } from "./../../../../environments/environment";
// import { User } from "./../models/user.model";
// import { Permission } from "../models/permissions.model";
// import { Role } from "../models/roles.model";
import { CoUsers } from "./../models/co_users.model";
import { CoPermissions } from "../models/co-permissions.model";
import { CoRoles } from "../models/co-roles.model";
import { catchError, map, mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}
  isAuthenticated(): Observable<any> {
    return this.http.get<any>(APIS.API_CORE_URL + "/tokenvalidation");
  }
  login(email: string, password: string): Observable<any> {
    return this.http.post<CoUsers>(APIS.API_CORE_URL + "/login", {
      user: email,
      pass: password,
    });
  }
  getUserByToken(): Observable<CoUsers> {
    return this.http.get<CoUsers>(APIS.API_CORE_URL + "/token");
  }
  getAllPermissions(): Observable<CoPermissions[]> {
    return this.http.get<CoPermissions[]>(APIS.API_CORE_URL + "/permissions");
  }
  getAllRoles(): Observable<CoRoles[]> {
    return this.http.get<CoRoles[]>(APIS.API_CORE_URL + "/roles");
  }
  register(user: CoUsers): Observable<any> {
    return this.http.post<CoUsers>(APIS.API_CORE_URL + "/register", user).pipe(
      map((res: CoUsers) => {
        return res;
      }),
      catchError((err) => {
        return err;
      })
    );
  }
  forgotPassword(email: string): Observable<any> {
    return this.http.get(APIS.API_CORE_URL + "/forgot/" + email);
  }
  recoverPassword(email: string, token: string): Observable<any> {
    return this.http.post(APIS.API_CORE_URL + "/recover", { email, token });
  }
  registerBeta(email: string, nombre: string): Observable<any> {
    return this.http.get(
      APIS.API_CORE_URL + "/confirmation/" + email + "/" + nombre
    );
  }
  confirmAccount(
    email: string,
    token: string,
    password: string,
    company: string,
    tipoNegocio: number,
    tipoIndustria: number,
    industria: string,
    tamanoEmpresa: number
  ): Observable<any> {
    return this.http.post(APIS.API_CORE_URL + `/confirmation-success`, {
      email,
      token,
      password,
      company,
      tipoNegocio,
      tipoIndustria,
      industria,
      tamanoEmpresa
    });
  }

  requestPassword(email: string): Observable<any> {
    return this.http.get(APIS.API_CORE_URL + "/recover/" + email);
  }
  requestPasswordSucess(
    email: string,
    token: string,
    password: string
  ): Observable<any> {
    return this.http.post(APIS.API_CORE_URL + `/recover-success`, {
      email,
      token,
      password,
    });
  }
}
