import { Injectable } from "@angular/core";
import { Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./../services/auth.service";
import { StoreService } from "./../services/store.service";
import { forkJoin, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthGuard  {
  constructor(
    private auth: AuthService,
    private router: Router,
    private store: StoreService
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((result) => {
      this.auth
        .isAuthenticated()
        .pipe(
          catchError((err) => {
            this.logOut();
            return of([]);
          })
        )
        .subscribe(
          async (res) => {
            if (res) {
              if (res.result) {
                const answer = await this.setData();
                if (answer) {
                  result(true);
                } else {
                  this.logOut();
                  result(false);
                }
              } else {
                this.logOut();
                result(false);
              }
            } else {
              this.logOut();
              result(false);
            }
          },
          (err) => {
            this.logOut();
            result(false);
          }
        );
    });
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((result) => {
      this.auth
        .isAuthenticated()
        .pipe(
          catchError((err) => {
            this.logOut();
            return of([]);
          })
        )
        .subscribe(
          async (res) => {
            if (res) {
              if (res.result) {
                const answer = await this.setData();
                if (answer) {
                  result(true);
                } else {
                  this.logOut();
                  result(false);
                }
              } else {
                this.logOut();
                result(false);
              }
            } else {
              this.logOut();
              result(false);
            }
          },
          (err) => {
            this.logOut();
            result(false);
          }
        );
    });
  }
  setData(): Promise<boolean> | boolean {
    return new Promise((result) => {
      forkJoin([
        this.auth.getUserByToken(),
        this.auth.getAllRoles(),
        this.auth.getAllPermissions(),
      ])
        .pipe(
          catchError((err) => {
            console.log(err);
            this.logOut();
            return of([]);
          })
        )
        .subscribe(
          (results) => {
            if (
              results[0] != null &&
              results[0].user.row_id !== undefined &&
              results[1] != null &&
              results[1].length > 0
            ) {
              // console.log(results);
              this.store.setUser(results[0].user);
              this.store.setRoles(results[1]);
              this.store.setPermissions(results[2]);
            }
          },
          (error) => {
            console.log(error);
            this.logOut();
            result(false);
          },
          () => {
            result(true);
          }
        );
    });
  }
  logOut(): void {
    localStorage.removeItem("token");
    this.router.navigateByUrl("login");
  }
}
