import { Injectable } from "@angular/core";
// import { User } from "./../models/user.model";
// import { Role } from "./../models/roles.model";
// import { Permission } from "./../models/permissions.model";
import { Country } from "./../models/country.model";
import { NgxPermissionsService } from "ngx-permissions";
import { CoUsers } from "./../models/co_users.model";
import { CoRoles } from "./../models/co-roles.model";
import { CoPermissions } from "./../models/co-permissions.model";

@Injectable({
  providedIn: "root",
})
export class StoreService {
  user: CoUsers = new CoUsers();
  roles: CoRoles[] = [];
  perms: CoPermissions[] = [];
  breadcrumb: any[] = [];
  countries: Country[];
  reviewData: any;
  constructor(public permissionsService: NgxPermissionsService) {
    this.countries = [
      new Country(1, "El Salvador"),
      new Country(2, "Costa Rica"),
      new Country(3, "Guatemala"),
      new Country(4, "Nicaragua"),
      new Country(5, "Panama"),
      new Country(6, "Honduras"),
    ];
  }
  //
  getUser(): CoUsers {
    return this.user;
  }
  getCountries(): Country[] {
    return this.countries;
  }
  setUser(data: CoUsers): void {
    this.user = data;
  }
  setRoles(data: CoRoles[]): void {
    this.roles = data;
  }
  getRoles(): CoRoles[] {
    return this.roles;
  }
  setPermissions(permissions: CoPermissions[]) {
    this.perms = permissions;
    // console.log(this.perms, this.roles);
    const perm = []; // tmp var to pre store permissions to be loaded
    // console.log(this.user.co_user_roles);
    for (let userRol of this.user.co_user_roles) {
      const tmpRol: CoRoles = this.roles.find(
        (rol) => rol.co_user_role_id === userRol.co_user_role_id
      );
      if (tmpRol !== undefined) {
        for (const rolperm of tmpRol.co_user_role_permissions) {
          const tmpperm = permissions.find(
            (perm1) => perm1.co_permissions_id === rolperm.co_permissions_id
          );
          if (tmpperm) {
            const check = perm.find(
              (exists) => exists === tmpperm.co_permissions_name
            );
            if (!check) {
              perm.push(tmpperm.co_permissions_name);
            } // push only if does not already exists
          }
        }
      }
    }
    // console.log(perm);
    this.permissionsService.loadPermissions(perm);
  }
  getPermissions(): CoPermissions[] {
    return this.perms;
  }
  logout(): void {
    this.user = new CoUsers();
    this.roles = [];
    this.perms = [];
    localStorage.setItem("token", "");
  }
  pushBreadcrumb(title: string): void {
    this.breadcrumb.push(title);
  }
  popBreadcrumb(): void {
    this.breadcrumb.pop();
  }
  public newBreadcrumb(title: string): void {
    this.breadcrumb = [];
    this.breadcrumb.push(title);
  }
  getBreadcrumb(): any[] {
    return this.breadcrumb;
  }
}
